import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { breakpoints } from '../../styles/variables'
import { Button } from '../../components/Button'
import { ButtonList } from '../../components/ButtonList'
import { Gallery } from '../../components/Gallery'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { ProductBlock } from '../../components/ProductBlock'
import { ProductImage } from '../../components/ProductImage'
import { ProductItem } from '../../components/ProductItem'
import { ProductRelatedList } from '../../components/ProductRelatedList'
import { SectionList } from '../../components/SectionList'
import { SpiritsPageQuery } from '../../../types/graphql-types'

export const query = graphql`
  query SpiritsPage {
    gin1Light: file(
      relativePath: { eq: "images/products-osuzu-gin-1@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gin1Dark: file(
      relativePath: { eq: "images/products-osuzu-gin-1@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gin2Light: file(
      relativePath: { eq: "images/products-osuzu-gin-2@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gin2Dark: file(
      relativePath: { eq: "images/products-osuzu-gin-2@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ginKumquat1Light: file(
      relativePath: { eq: "images/products-osuzu-gin-kumquat-1@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ginKumquat1Dark: file(
      relativePath: { eq: "images/products-osuzu-gin-kumquat-1@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ginKumquat2Light: file(
      relativePath: { eq: "images/products-osuzu-gin-kumquat-2@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ginKumquat2Dark: file(
      relativePath: { eq: "images/products-osuzu-gin-kumquat-2@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whiskyLight: file(
      relativePath: { eq: "images/products-whisky@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whiskyDark: file(relativePath: { eq: "images/products-whisky@dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shochuLight: file(
      relativePath: { eq: "images/products-shochu@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shochuDark: file(relativePath: { eq: "images/products-shochu@dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface SpiritsPageProps extends AppPageProps {
  data: SpiritsPageQuery
}

const SpiritsPage: React.FC<SpiritsPageProps> = ({
  pathContext: { langKey },
  data: {
    gin1Light,
    gin1Dark,
    gin2Light,
    gin2Dark,
    ginKumquat1Light,
    ginKumquat1Dark,
    ginKumquat2Light,
    ginKumquat2Dark,
    whiskyLight,
    whiskyDark,
    shochuLight,
    shochuDark,
  },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="スピリッツ"
    titleSublabel="スピリッツ"
    titleLabel="Spirits"
    headerMode="product"
  >
    <SectionList>
      <BlockList>
        <ProductBlock
          image={
            <LoadingContext.Provider value="eager">
              <Gallery>
                <ProductImage
                  fluidLight={gin1Light?.childImageSharp?.fluid as FluidObject}
                  fluidDark={gin1Dark?.childImageSharp?.fluid as FluidObject}
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
                <ProductImage
                  fluidLight={gin2Light?.childImageSharp?.fluid as FluidObject}
                  fluidDark={gin2Dark?.childImageSharp?.fluid as FluidObject}
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
              </Gallery>
            </LoadingContext.Provider>
          }
          sublabel="スピリッツ"
          label="OSUZU GIN"
        >
          <p>
            私たちが伝統的な手仕事で造った本格焼酎に地元の素材を中心とした様々なボタニカルを漬け込んで蒸留した大地の香水のようなクラフトジンです。
          </p>
          <p>
            OSUZU GIN 200ml:
            <br />
            税込価格: 2,035円
            <br />
            容量: 200ml
            <br />
            度数: 45度
          </p>
          <p>
            OSUZU GIN 700ml:
            <br />
            税込価格: 4,290円
            <br />
            容量: 700ml
            <br />
            度数: 45度
          </p>
          <ButtonList>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/36"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (200ml)
            </Button>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/37"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (700ml)
            </Button>
          </ButtonList>
        </ProductBlock>
        <ProductBlock
          image={
            <LoadingContext.Provider value="eager">
              <Gallery>
                <ProductImage
                  fluidLight={
                    ginKumquat1Light?.childImageSharp?.fluid as FluidObject
                  }
                  fluidDark={
                    ginKumquat1Dark?.childImageSharp?.fluid as FluidObject
                  }
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
                <ProductImage
                  fluidLight={
                    ginKumquat2Light?.childImageSharp?.fluid as FluidObject
                  }
                  fluidDark={
                    ginKumquat2Dark?.childImageSharp?.fluid as FluidObject
                  }
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
              </Gallery>
            </LoadingContext.Provider>
          }
          sublabel="スピリッツ"
          label="OSUZU GIN Kumquat"
        >
          <p>
            オリジナルの尾鈴ジンと同じく山ねこをベースにしたスピリッツに宮崎県産の無農薬金柑を贅沢に使いました。その他のボタニカルは、シンプルに主役の金柑を引き立てるようジュニパー、山椒、生姜を使用しています。
          </p>
          <p>
            OSUZU GIN Kumquat 200ml:
            <br />
            税込価格: 2,035円
            <br />
            容量: 200ml
            <br />
            度数: 45度
          </p>
          <p>
            OSUZU GIN Kumquat 700ml:
            <br />
            税込価格: 4,290円
            <br />
            容量: 700ml
            <br />
            度数: 45度
          </p>
          <ButtonList>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/43"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (200ml)
            </Button>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/42"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (700ml)
            </Button>
          </ButtonList>
        </ProductBlock>
      </BlockList>
      <ProductRelatedList>
        <ProductItem
          to="/products/whisky/"
          fluidLight={whiskyLight?.childImageSharp?.fluid as FluidObject}
          fluidDark={whiskyDark?.childImageSharp?.fluid as FluidObject}
          sublabel="ウイスキー"
          label="Whisky"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 20vw, 40vw`}
        />
        <ProductItem
          to="/products/shochu/"
          fluidLight={shochuLight?.childImageSharp?.fluid as FluidObject}
          fluidDark={shochuDark?.childImageSharp?.fluid as FluidObject}
          sublabel="焼酎"
          label="Shochu"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 20vw, 40vw`}
        />
      </ProductRelatedList>
    </SectionList>
  </Layout>
)

export default SpiritsPage
